import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../http-service/auth.service';
import { Router, ActivationEnd, NavigationEnd } from '@angular/router';
import { HttpCancelService } from './http-cancel.service';
import { takeUntil, tap } from 'rxjs/operators';
import { CookieService } from '@vex/services/cookie.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@vex/services/helper.service';

declare var $;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private cookie: CookieService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private helperService: HelperService,
    private httpCancelService: HttpCancelService) {
    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd)  {
        this.dialog.closeAll();
        this.helperService.loadingSpinner = false;
        $('#nodeTooltip').remove();
      }
      
      // An event triggered at the end of the activation part of the Resolve phase of routing.
      if (event instanceof ActivationEnd) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests();

        if (event.snapshot) {
          if (event.snapshot.routeConfig && event.snapshot.routeConfig.path) {
            let path = '';
            path = path === '' ? event.snapshot.routeConfig.path : [event.snapshot.routeConfig.path, path].join('/');
            if (!path.includes('login')) {
            }
          }
        }
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.url.includes('Account/Login') && !req.url.includes('Account/ForgotPassword') && !req.url.includes('Account/ResetPassword')) {
      if (this.authService.isLoggedIn()) {
        const authToken = localStorage.getItem('token');
        req = req.clone({
          setHeaders: { Authorization: 'Bearer ' + authToken }
        });
      } else {
        return;
      }
    }

    return next.handle(req).pipe(
      // For cancelling previous requests
      takeUntil(this.httpCancelService.onCancelPendingRequests()),
      // If an error occured in api request or 401
      tap(
        () => { },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            let errorMsg = '';
            if (error.error instanceof ErrorEvent) {
              // this.toastrService.error(error.error.message, 'Error');
              console.log('This is client side error');
              errorMsg = `Error: ${error.error.message}`;
            } else {
              if(!req.url.includes('Account/Login') && !req.url.includes('Account/ForgotPassword') && !req.url.includes('Account/ResetPassword')) {
                let expireToast = this.cookie.getCookie('_Kumorai-Error-Toast');
                if (!expireToast) {
                  let expire = new Date(Date.now() + 5 * 1000).toUTCString();
                  this.cookie.setCookie({
                    name: '_Kumorai-Error-Toast',
                    value: expire,
                    expireDate: expire
                  });
                  // this.toastrService.error(error.message, 'Error Code: '+error.status);
                }
              }
              console.log('This is server side error');
              errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
            console.log(errorMsg);
            return;
          }
        }
      )
    );
  }
}