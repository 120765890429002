import { Injectable } from '@angular/core';
import {
    ConfirmDialogModel,
    ConfirmDialogComponent
} from '@vex/components/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogTitle,
    ConfirmDialogMessage,
    ConfirmDialogMaxWidth,
    PageSizeOptions,
    PageSize
} from '@vex/constants/settings';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class HelperService {
    dialogData = new ConfirmDialogModel(ConfirmDialogTitle, ConfirmDialogMessage);
    confirmModalData = {
        maxWidth: ConfirmDialogMaxWidth,
        data: this.dialogData
    };
    // loading spinner set true on http calls until response is returned
    loadingSpinner: boolean = false;
    // for change request process
    changeRequestData: any;

    constructor(private dialog: MatDialog, private location: Location) {}

    getDuration(totalseconds) {
        let year = 365 * 86400;
        let month = 30 * 86400;
        let day = 86400;
        let hour = 3600;
        let minute = 60;

        let yearsout = Math.floor(totalseconds / year);
        let yearsec = yearsout * year;
        let monthsout = Math.floor((totalseconds - yearsec) / month);
        let monthsec = monthsout * month;
        let daysout = Math.floor((totalseconds - yearsec - monthsec) / day);
        let daysec = daysout * day;
        let hoursout = Math.floor((totalseconds - yearsec - monthsec - daysec) / hour);
        let hoursec = hoursout * hour;
        let minutesout = Math.floor(
            (totalseconds - yearsec - monthsec - daysec - hoursec) / minute
        );
        let minutesec = minutesout * minute;
        let secondsout = totalseconds - yearsec - monthsec - daysec - hoursec - minutesec;
        let duration = {
            year: yearsout,
            month: monthsout,
            day: daysout,
            hour: hoursout,
            minute: minutesout,
            second: secondsout
        };
        return duration;
    }

    confirmationScreen(screenRef: any): any {
        const message = `Are you sure you want to close this Screen?`;

        const dialogData = new ConfirmDialogModel('Confirm Action', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: dialogData
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult === true) {
                screenRef.close();
            }
        });
    }

    onClose() {
        const currentUrl = this.location.path();
        const indexOfId = currentUrl.indexOf('id=');
        if (indexOfId !== -1) {
            const updatedUrl = currentUrl.substring(0, indexOfId - 1);
            this.location.replaceState(updatedUrl);
        }
    }
}
